.list {
    /* width:968px; margin-left:-4px; overflow: hidden; */
    width: 996px;
    overflow: hidden;
    padding-left: 2px;
    padding-bottom: 1px;
    /* display: flex;
justify-content: space-between;
flex-wrap: wrap; */
}
.videoCard {
    margin-top: 20px;
    width: 630px;
    height: 368px;
    float: left;
}
