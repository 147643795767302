.col_wbf {
    width: 100%;
    margin: 0 auto;
    position: relative;
}
.bg_top {
    background: #fff;
    padding-bottom: 30px;
    overflow: hidden;
}
.col_w1000 {
    width: 1000px;
    margin: 0 auto;
    overflow: hidden;
}
.mt25 {
    margin-top: 25px;
}
.top {
    height: 98px;
}
.logo {
    width: 180px;
    float: left;
    margin-top: 16px;
}
.nav {
    width: 750px;
    float: right;
    overflow: hidden;
}
.nav_box {
    width: 240px;
    height: 100px;
    float: left;
    margin-right: 10px;
}
.nav_tit {
    width: 24px;
    height: 100px;
    font-size: 20px;
    font-weight: bold;
    line-height: 22px;
    padding-right: 30px;
    float: left;
    margin-right: 10px;
}
.nav_tit a {
    color: #113a68;
    text-decoration: none;
    transition: color 0.5s;
    -webkit-transition: color 0.5s;
    -moz-transition: color 0.5s;
    -ms-transition: color 0.5s;
    -o-transition: color 0.5s;
}
.nav_tit a:hover {
    color: #f64346;
}
.n1_bg {
    background: url(./n1_bg.jpg) no-repeat 10px -2px;
}
.n2_bg {
    background: url(./n2_bg.jpg) no-repeat 10px -2px;
}
.n3_bg {
    background: url(./n3_bg.jpg) no-repeat 10px -2px;
}
.nav_list {
    width: 176px;
    padding: 16px 0;
    float: left;
}
.nav_list li {
    font-size: 16px;
    height: 34px;
    line-height: 34px;
    overflow: hidden;
}
.nav_list li a {
    color: #222;
    margin-right: 15px;
    text-decoration: none;
    transition: color 0.5s;
    -webkit-transition: color 0.5s;
    -moz-transition: color 0.5s;
    -ms-transition: color 0.5s;
    -o-transition: color 0.5s;
}
.nav_list li a:hover {
    color: #f64346;
}
