.col_w1000 {
    width: 1000px;
    margin: 0 auto;
    overflow: hidden;
}
.bot {
    border-top: 2px solid #f54343;
    padding-top: 20px;
}
.box_300 {
    width: 289px;
    float: left;
    padding: 0 50px 0 10px;
    border-right: 1px solid #ebebeb;
}
.b_xx img {
    float: left;
    margin-right: 15px;
}
.b_xx p {
    color: #666;
    line-height: 24px;
}
.box_650 {
    width: 650px;
    float: left;
}
.box_650 h4 {
    font-size: 18px;
    color: #222;
    font-weight: bold;
    padding-left: 65px;
    margin-bottom: 10px;
}
.box_650 ul {
    width: 77px;
    padding: 0 68px 0 70px;
    border-right: 1px solid #ebebeb;
    float: left;
}
.box_650 .u_bn {
    border: none;
}
.box_650 li {
    color: #666;
    line-height: 26px;
}
.box_650 a {
    color: #666;
}
.mt20 {
    margin-top: 20px;
}
