.col_wbf {
    width: 100%;
    height: auto;
    margin: 0 auto 0;
    position: relative;
}

.bg_poz {
    width: 1000px;
    margin: 0 auto;
    position: relative;
    top: -120px;
    padding-top: 30px;
    background: #fff;
}

.col_w960 {
    width: 960px;
    margin: 0 auto;
}
