.main_nav {
    width: 980px;
    height: 26px;
    margin: 0 auto;
    padding: 7px 10px 3px;
    font-family: '宋体';
    position: relative;
    z-index: 999;
    background: #fff;
}

.box {
    width: 1000px;
    height: 90px;
    float: right;
    text-align: center;
    line-height: 90px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    position: relative;
    padding: 0;
    margin: 0;
}

.ad {
    width: 1000px;
    height: 90px;
    margin: 0 auto;
}

.search {
    width: 1000px;
    margin: 0 auto;
    height: 26px;
    padding-top: 28px;
    padding-bottom: 10px;
}

.logo {
    float: left;
    font-size: 12px;
    & a img {
        position: relative;
        left: 0;
        top: 0;
        right: 361px;
        float: left;
    }
}
.nav_box {
    padding-top: 2px;
    clear: both;
    float: left;
}

.userInfo {
    float: left;
    padding-top: 3px;
    padding-left: 10px;
}
