/*scroll*/

.s_ban {
    width: 100%;
    height: 600px;
    position: relative;

    & :global([ifeng_ui_pc_sliders-arrows_pre]) {
        width: 60px;
        height: 60px;
        background: url(./images/btn_l.png) no-repeat;
        position: absolute;
        top: 215px;
        left: 50%;
        z-index: 99;
        margin-left: -580px;
        cursor: pointer;
    }

    & :global([ifeng_ui_pc_sliders-arrows_pre]:hover),
    & :global([ifeng_ui_pc_sliders-arrows_next]:hover) {
        background-color: transparent;
    }
    & :global([ifeng_ui_pc_sliders-arrows_next]) {
        width: 60px;
        height: 60px;
        background: url(./images/btn_r.png) no-repeat;
        position: absolute;
        top: 215px;
        right: 50%;
        margin-right: -580px;
        z-index: 99;
        cursor: pointer;
    }
    & :global([ifeng_ui_pc_sliders-dots]) {
        bottom: 160px;
        right: 50%;
        transform: (50%, 0, 0);
        height: 10px;
    }
    & .dot {
        width: 10px;
        height: 10px;
        background: #fff;
        border-radius: 50%;
        display: inline-block;
        margin: 0 5px;
        cursor: pointer;
    }
    & .current {
        width: 10px;
        height: 10px;
        background: #fff;
        border-radius: 50%;
        display: inline-block;
        margin: 0 5px;
        cursor: pointer;
        background: #f54341;
    }
}

.s_ban_main {
    width: 100%;
    height: 600px;
    overflow: hidden;
}
/* 
.s_ban_main ul {
    width: 100%;
}
 */
.s_ban_main .li {
    position: relative;
}

.s_ban_main .blockA,
.s_ban_main .bg {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 600px;
    /* background: url(./images/zz_bg.png) repeat-x center bottom; */
}
.s_ban_main .bg {
    background: url(./images/zz_bg.png) repeat-x center bottom;
}
.s_ban_txt {
    width: 610px;
    height: 110px;
    position: absolute;
    top: 246px;
    left: 50%;
    margin-left: -325px;
    padding: 25px 20px 25px;
}

.s_ban_txt .text_swiper {
    width: 610px;
    height: 110px;
    position: absolute;
    z-index: 2;
}

.s_ban_txt .textBg {
    width: 650px;
    height: 160px;
    position: absolute;
    left: 0px;
    top: 0px;
    background: #000;
    filter: alpha(opacity=65);
    -moz-opacity: 0.65;
    -khtml-opacity: 0.65;
    opacity: 0.65;
    z-index: 1;
}

.s_ban_main ul a:hover,
.s_ban_txt a:hover {
    text-decoration: none;
}

.s_ban_txt h2,
.l_box3 h3,
.l_pt h3 {
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    background: url(./images/line1.jpg) no-repeat left top;
    padding-top: 6px;
}

.s_ban_txt a,
.l_box3 h3 a,
.l_pt h3 a {
    color: #fff;
}

.s_ban_txt p,
.l_box3 p,
.l_pt p {
    font-size: 16px;
    color: #fff;
    height: 60px;
    line-height: 30px;
    margin-top: 10px;
    overflow: hidden;
}

.s_ban_txt h2:hover,
.l_box3 h3:hover,
.l_pt h3:hover {
    background: url(./images/line2.jpg) no-repeat left top;
    transition: all 1s;
}

.s_ban_txt h2 a {
    display: block;
    max-width: 25em;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.s_ban_txt h2 a:hover {
    color: #f64346;
}
