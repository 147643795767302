/*热点新闻*/
.hot {
    height: 62px;
    composes: clearfix from global;
}
.h_date {
    width: 60px;
    height: 22px;
    font-family: Microsoft YaHe;
    font-size: 18px;
    color: #222;
    text-align: center;
    padding-top: 40px;
    background: url(./images/rl_bg.jpg) no-repeat center top;
    float: left;
    margin-right: 10px;
}
.h_list {
    float: left;
}
.h_list li {
    width: 235px;
    height: 62px;
    background: #f0f0f0;
    border-radius: 10px;
    position: relative;
    margin-right: 10px;
    float: left;
}
.h_list li span {
    display: block;
    width: 105px;
    height: 62px;
    background: url(./images/h_bg.png) no-repeat;
    position: absolute;
    left: 0px;
    top: 0px;
}
.h_list li img {
    width: 105px;
    height: 62px;
    float: left;
}
.h_list li p {
    font-size: 14px;
    color: #222;
    height: 48px;
    line-height: 24px;
    padding: 7px 0;
    width: 100px;
    margin-right: 15px;
    float: right;
    overflow: hidden;
}
.h_list li p a {
    color: #222;
}
.h_btn {
    width: 155px;
    height: 62px;
    line-height: 62px;
    text-align: center;
    background: #113a68;
    font-size: 18px;
    font-weight: bold;
    color: #fbfbfb;
    border-radius: 10px;
    float: right;
}
.h_btn:hover {
    background: #f64346;
}
.h_btn a {
    color: #fbfbfb;
}
.h_btn a:hover {
    font-size: 20px;
    text-decoration: none;
}
.h_list li p a:hover {
    color: #f64346;
    text-decoration: none;
    transition: 0.3s all;
}
