.l_box {
    width: 300px;
    height: 368px;
    box-shadow: 0px 0px 2px #bdbdbd;
    border-radius: 10px;
    overflow: hidden;
    margin: 20px 30px 0 0;
}
.l_box .pic,
.l_box img {
    width: 300px;
}
.l_box .txt {
    width: 260px;
    padding: 0 20px;
}
.l_box h3 {
    font-family: Microsoft YaHei;
    font-size: 18px;
    font-weight: bold;
    height: 60px;
    line-height: 24px;
    margin-top: 20px;
    overflow: hidden;
}
.l_box p {
    height: 54px;
    line-height: 18px;
    margin-top: 10px;
    overflow: hidden;
}
.l_box .l_xx {
    height: 22px;
    line-height: 22px;
    margin-top: 10px;
    overflow: hidden;
}
.l_box .l_time {
    float: left;
}
.l_box .l_source {
    float: right;
}
.l_source a {
    text-decoration: none;
}
.l_box .l_source a {
    color: #666;
}
.trans {
    width: 960px;
    height: 540px;
    transition: 0.5s all ease;
}
.trans:hover {
    transform: scale(1.1, 1.1);
}
.l_box1 {
    position: relative;
}
.l_box1 .pic {
    width: 300px;
    height: 168px;
    overflow: hidden;
    position: relative;
}
.l_box1 .pic,
.l_box1 img {
    height: 168px;
}
.l_box1 .txt {
    color: #666;
}
.l_box1 h3 {
    color: #222;
}
.l_box1 h3 a::before {
    content: '';
    display: block;
    width: 40px;
    border-top: 1px solid #f64346;
    margin: 0 auto;
    padding-top: 10px;
    transition: 0.5s all;
}
.l_box1 h3 a:hover::before {
    width: 260px;
}
.l_box1 h3 a {
    color: #222;
    display: block;
    text-decoration: none;
    transition: 0.5s all;
}
.l_box1 h3 a:hover {
    color: #f64346;
}
.l_box1 .v_btn {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 50%;
    margin-left: -25px;
    top: 58px;
}
.l_box1 .v_btn a {
    display: block;
    width: 50px;
    height: 50px;
    background: url(./video_btn1.png) no-repeat;
    transition: 0.5s all;
}
.l_box1 .v_btn a:hover {
    text-decoration: none;
    background: url(./video_btn1_on.png) no-repeat;
}
.l_box1:hover {
    background: #f6f6f6;
}

.l_box2 {
    position: relative;
}
.l_box2 .pic,
.l_box2 img {
    height: 368px;
}
.l_box2 .pic {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 1;
}
.l_box2 .zz {
    width: 300px;
    height: 368px;
    background: url(./zz.png) repeat-x;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 2;
}
.l_box2 .txt {
    color: #fff;
    position: absolute;
    left: 0px;
    top: 168px;
    z-index: 3;
}
.l_box2 h3 {
    color: #fff;
}
.l_box2 h3 a {
    text-decoration: none;
    color: #fff;
    transition: 0.5s all;
}
.l_box2 h3 a:hover {
    color: #f64346;
}
.l_box2 .l_source a {
    color: #666;
    transition: 0.5s all;
}
.l_source a:hover {
    color: #f64346;
}
.l_box3 {
    width: 630px;
    height: 368px;
    position: relative;
}
.l_box3 .pic,
.l_box3 .pic img {
    width: 630px;
    height: 368px;
    overflow: hidden;
}
.l_box3 .textBg {
    width: 630px;
    height: 138px;
    position: absolute;
    left: 0px;
    top: 230px;
    background: #000;
    filter: alpha(opacity=65);
    -moz-opacity: 0.65;
    -khtml-opacity: 0.65;
    opacity: 0.65;
}
.l_box3 .txt {
    width: 590px;
    height: 88px;
    padding: 25px 20px 25px;
    position: absolute;
    left: 0px;
    top: 230px;
}

.l_video {
    background: #000;
}
.l_video #playerDiv {
    width: 630px;
    height: 368px;
}
.l_video ul {
    width: 630px;
    overflow: hidden;
}
.l_video li {
    width: 630px;
    height: 368px;
    float: none;
    display: none;
}

.l_pt {
    width: 960px;
    height: 540px;
    position: relative;
}
.l_pt .pic {
    width: 960px;
    height: 540px;
    overflow: hidden;
}
.l_pt .textBg {
    width: 650px;
    height: 160px;
    position: absolute;
    left: 30px;
    top: 350px;
    background: #000;
    filter: alpha(opacity=65);
    -moz-opacity: 0.65;
    -khtml-opacity: 0.65;
    opacity: 0.65;
}
.l_pt .txt {
    width: 610px;
    height: 110px;
    padding: 25px 20px 25px;
    position: absolute;
    left: 30px;
    top: 350px;
}
.s_ban_txt h2,
.l_box3 h3,
.l_pt h3 {
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    display: inline;
    background: url(./line.jpg) no-repeat left top;
    padding-top: 6px;
}
.s_ban_txt a,
.l_box3 h3 a,
.l_pt h3 a {
    color: #fff;
    text-decoration: none;
    transition: 1s all;
}
.l_box3 h3 a:hover,
.l_pt h3 a:hover {
    color: #f64346;
}
.s_ban_txt p,
.l_box3 p,
.l_pt p {
    font-size: 16px;
    color: #fff;
    height: 60px;
    line-height: 30px;
    margin-top: 10px;
    overflow: hidden;
}
.s_ban_txt h2:hover,
.l_box3 h3:hover,
.l_pt h3:hover {
    background: url(./line2.jpg) no-repeat left top;
    transition: all 1s;
}

.l_box,
.l_box3,
.l_pt {
    margin-top: 20px;
    margin-right: 30px;
    float: left;
}
